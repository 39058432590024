import { Button } from "@mui/material"
import {
  FilterSearchAndSelect,
  FilterToggle,
  PageHeader,
  PaperContent,
  ROLES,
  ReferentialValue,
  Table,
  useDialog,
  useQueryClient,
  useRoleCheck,
} from "@pharmupp/p3-front-commons"
import { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ActivateOperationModal } from "../BonusOperationDisplay/ActivateOperationModal"
import { BonusOpColumnApi } from "./conf/columns"
import { BonusOperation } from "./models/BonusOperation.model"
import { useBonusOperationList } from "./useBonusOperationList"

export function BonusOperationList() {
  const isAdmin = useRoleCheck(ROLES.ADMIN)

  // Routing
  const { year } = useParams()
  const navigate = useNavigate()
  const goToDetails = (id: number) => navigate(`${id}`)
  const goToParticipants = (id: number) => navigate(`${id}/participation`)

  const dialog = useDialog()
  const queryClient = useQueryClient()

  // API
  const {
    listConf,
    operationToActivate,
    setOperationToActivate,
    referential,
    referentialLoading,
    resourceUrl,
  } = useBonusOperationList()
  const queryKey = ["bonus-operation", "list", year]

  const statusOptions = useMemo<(ReferentialValue<string> & { color: string })[]>(
    () =>
      (referential?.status || []).map((status) => ({
        ...status,
        value: status.code,
      })),
    [referential?.status],
  )

  return (
    <>
      {/* HEADER */}
      <PageHeader title={`Règles Vie des UPP ${year || ""}`}>
        {!!listConf.canCreate && (
          <Button variant="contained" onClick={() => navigate("create")}>
            Créer une opération
          </Button>
        )}
      </PageHeader>

      {/* TABLE */}
      <PaperContent loading={referentialLoading}>
        <Table<BonusOperation, BonusOperation, BonusOpColumnApi>
          queryConfig={{
            queryKey,
            resourceEndpoint: resourceUrl,
            queryParams: { year },
            defaultColumnSort: "date",
            defaultColumnDirection: "desc",
          }}
          FilterSectionElement={
            isAdmin ? (
              <>
                <FilterSearchAndSelect
                  name="status"
                  label="statut"
                  options={statusOptions}
                />
                <FilterSearchAndSelect
                  name="year"
                  label="année"
                  options={[
                    { label: `${currentYear}`, value: currentYear },
                    { label: `${currentYear - 1}`, value: currentYear - 1 },
                    { label: `${currentYear - 2}`, value: currentYear - 2 },
                  ]}
                />
                <FilterToggle name="activeRequests" label="Demandes à valider" />
              </>
            ) : (
              <>
                <FilterToggle name="isActive" label="Opérations actives" />
                <FilterToggle name="isSubmitted" label="Soumises" />
                <FilterToggle name="isActivable" label="Participation en cours" />
              </>
            )
          }
          searchDefaultOpen
          emptyMessage="Vous n'avez pas encore d'opération vie"
          columns={listConf.columns}
          onRowClick={(row) => goToDetails(row.id || row.bonusOperationId)}
          extraRowApi={{
            dialog,
            showActivationModal: (row: BonusOperation) =>
              setOperationToActivate(row),
            goToDetails,
            goToParticipants,
            referential: {
              statusOptions,
            },
          }}
          fixedLayout
        />
      </PaperContent>

      {/* DIALOGS */}
      <ActivateOperationModal
        open={!!operationToActivate}
        onClose={() => setOperationToActivate(undefined)}
        onSuccess={() => queryClient.invalidateQueries({ queryKey })}
        operation={operationToActivate}
      />
    </>
  )
}

const currentYear = new Date().getFullYear()
