import {
  Rating as MuiRating,
  RatingProps as MuiRatingProps,
  ratingClasses,
  styled,
} from "@mui/material"
import { ComponentType } from "react"

export type RatingProps = Omit<MuiRatingProps, "size"> & {
  size?: MuiRatingProps["size"] | "extraLarge"
}

export const Rating = ({ value, ...props }: RatingProps) => (
  <StyledRating
    value={value != null ? Math.floor(value) : value}
    {...props}
    readOnly
  />
)

const StyledRating = styled(MuiRating as ComponentType<RatingProps>)(
  ({ theme, size }) => ({
    ...(size === "extraLarge" && {
      [`& .${ratingClasses.icon}`]: {
        fontSize: theme.typography.pxToRem(34),
      },
    }),
  }),
)
