import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  DialogProps as MuiDialogProps,
  Stack,
  styled,
} from "@mui/material"
import { X } from "react-feather"
import { PaperContainerTitle } from "../../layout"

export type ModalProps = Omit<MuiDialogProps, "onClose"> & {
  onClose?: () => void
  loading?: boolean
  showCross?: boolean
}

/**
 * Modals allows more customization on content than Dialogs (lower level than dialogs)
 */
export const Modal = ({
  open,
  onClose,
  loading = false,
  showCross = false,
  sx,
  children,
  ...props
}: ModalProps) => (
  <Dialog
    {...props}
    open={open}
    onClose={onClose}
    PaperProps={{ elevation: 1, sx: { ...sx, p: 5, pb: 4 } }}
  >
    {loading ? (
      <CircularProgress />
    ) : (
      <>
        {showCross && onClose && (
          <ModalCrossButton onClick={() => onClose()}>
            <X />
          </ModalCrossButton>
        )}
        {children}
      </>
    )}
  </Dialog>
)

export const ModalTitle = PaperContainerTitle

export const ModalContent = styled(DialogContent)(({ theme: { spacing } }) => ({
  padding: spacing(4, 0),
}))

export const ModalContentText = styled(DialogContentText)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(20),
    lineHeight: typography.pxToRem(22),
    fontWeight: "normal",
    color: palette.grey.dark,
  }),
)

export const ModalDialogActions = styled(DialogActions)(() => ({}))

export interface ModalBasicActionsProps {
  cancelAction?: () => void
  cancelText?: string
  validateAction: () => void
  validateText?: string
  validateEnabled?: boolean
}
export const ModalBasicActions = ({
  cancelAction,
  cancelText = "Annuler",
  validateAction,
  validateText = "Valider",
  validateEnabled = true,
}: ModalBasicActionsProps) => (
  <Stack spacing={4}>
    <Divider sx={{ borderColor: "#f0f1f2" }} />
    <ModalDialogActions>
      {!!cancelAction && <Button onClick={cancelAction}>{cancelText}</Button>}
      <Button
        onClick={validateAction}
        variant="contained"
        disabled={!validateEnabled}
      >
        {validateText}
      </Button>
    </ModalDialogActions>
  </Stack>
)

const ModalCrossButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
}))
