import { Box, Skeleton, Stack } from "@mui/material"
import { formatNumber, formatPrice } from "@pharmupp/p3-front-commons"
import { useStocksSummaryApi } from "../../../api/useStocksSummaryApi"
import { MainKpiCard, SectionLayout } from "../commons"
import { StockCard } from "./StockCard"

const today = new Date().toLocaleDateString()
const STOCK_SIZE = 4

export const Stocks = ({ labId }: { labId: string }) => {
  const { stocksSummary, isLoading } = useStocksSummaryApi({
    labId,
  })

  return (
    <SectionLayout>
      <Stack gap={2}>
        <MainKpiCard
          title="STOCKS"
          subTitle="Valeur PF HT Acheté"
          kpi={formatPrice(Number(stocksSummary?.amount ?? 0))}
        />
      </Stack>

      <Box display="grid" gridTemplateColumns={`repeat(${STOCK_SIZE}, 1fr)`} gap={2}>
        {isLoading ? (
          <>
            {Array.from({ length: STOCK_SIZE }, (_, index) => (
              <Skeleton key={index} variant="rectangular" height={100} />
            ))}
          </>
        ) : (
          <>
            <StockCard
              title="nb références invendues"
              stockValue={formatNumber(stocksSummary?.unsoldAmount ?? 0)}
            />
            <StockCard
              title="qté produits invendus"
              stockValue={formatNumber(stocksSummary?.unsoldProductsCount ?? 0)}
            />
            <StockCard
              title="valeur invendus"
              stockValue={formatPrice(stocksSummary?.unsoldReferencesCount ?? 0)}
              tooltip="Valeur des produits en stock qui n'ont eu aucune vente depuis 6 mois"
            />
            <StockCard
              title="coût ht périmés"
              stockValue={formatPrice(stocksSummary?.expiredProductsAmount ?? 0)}
              dateLine={today}
            />
          </>
        )}
      </Box>
    </SectionLayout>
  )
}
