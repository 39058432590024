import { Box, Divider, Stack, Typography } from "@mui/material"
import {
  Modal,
  ModalBasicActions,
  ModalContent,
  ModalProps,
  ModalTitle,
  Rating,
  formatNumber,
} from "@pharmupp/p3-front-commons"
import { useRatingApi } from "../../../api/useRatingApi"

export function RatingModal({
  open,
  onClose,
  labId,
}: ModalProps & { labId: string }) {
  const { rating } = useRatingApi({ labId })

  return (
    <Modal open={open} onClose={onClose} showCross sx={{ minWidth: "720px" }}>
      <ModalTitle>Avis des Adhérents</ModalTitle>
      <ModalContent>
        <Stack spacing={5.5} divider={<Divider />}>
          {/* GLOBAL SCORE */}
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="30px" fontWeight={600} color="primary.dark1">
              {rating.averageValue != null ? formatNumber(rating.averageValue) : "-"}
            </Typography>
            <Rating value={rating.averageValue} max={rating.max} size="extraLarge" />
            <Typography variant="19px" fontWeight={500} color="#91a2c3">
              ({rating.ratingsCount} avis)
            </Typography>
          </Stack>

          {/* SCORE BY CATEGORY */}
          <Stack spacing={3}>
            <CategoryScore
              name="Qualité"
              score={rating.averageQualityValue}
              max={rating.max}
            />
            <CategoryScore
              name="Conditions commerciales "
              score={rating.averageSalesConditionsValue}
              max={rating.max}
            />
            <CategoryScore
              name="Disponibilité produits"
              score={rating.averageProductAvailabilityValue}
              max={rating.max}
            />
            <CategoryScore
              name="Accompagnement"
              score={rating.averageSupportValue}
              max={rating.max}
            />
          </Stack>
        </Stack>
      </ModalContent>

      <ModalBasicActions
        validateAction={() => alert("Validate")}
        validateText="Donner mon avis"
        validateEnabled={false}
      />
    </Modal>
  )
}

const CategoryScore = ({
  name,
  score,
  max,
}: { name: string; score?: number; max: number }) => (
  <Box
    display="grid"
    gridTemplateColumns="1.5fr 1fr 1fr"
    alignItems="center"
    columnGap={3}
    width="100%"
  >
    <Typography variant="14px" fontWeight={500} color="primary.dark1">
      {name}
    </Typography>
    <Rating value={score} max={max} size="large" />
    {score != null && (
      <Typography variant="14px" fontWeight={500} color="primary.dark1">
        {getScoreLabel(score)}
      </Typography>
    )}
  </Box>
)

const getScoreLabel = (score: number) => {
  switch (Math.floor(score)) {
    case 1:
      return "Très insatisfait"
    case 2:
      return "Insatisfait"
    case 3:
      return "Satisfait"
    case 4:
      return "Très satisfait"
    case 5:
      return "Top!"
    default:
      return ""
  }
}
