import {
  FieldSection,
  FormSearchField,
  FormTextField,
} from "@pharmupp/p3-front-commons"
import { UserFormReferential } from "../../../useUserFormApi"
import { fields } from "../GeneralInfos.fields"
import { useConditionalTypeFields } from "./useConditionalTypeFields"

interface GeneralInfosProps {
  referential: UserFormReferential
}

export const ConditionalTypeFields = ({ referential }: GeneralInfosProps) => {
  const { isExternSpecificationVisible, isFunctionDisabled, isFunctionVisible } =
    useConditionalTypeFields(referential)

  return (
    <FieldSection title="Type" subtitle="Sélectionner un type de profil utilisateur">
      <FormSearchField
        label="Type"
        name={fields.type}
        options={referential.type}
        required
        valueFormat="optionValue"
      />
      {!!isFunctionVisible && (
        <FormTextField
          label="Fonction"
          name={fields.function}
          disabled={isFunctionDisabled}
        />
      )}
      {!!isExternSpecificationVisible && (
        <FormSearchField
          label="Spécification de l'externe"
          name={fields.externSpecification}
          options={referential?.externSpecification}
          valueFormat="optionValue"
        />
      )}
    </FieldSection>
  )
}
