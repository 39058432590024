import { useFormContext, useWatch } from "react-hook-form"
import { FormValues } from "../../../UserForm.component"
import { UserFormReferential } from "../../../useUserFormApi"
import { fields } from "../GeneralInfos.fields"

export const useConditionalTypeFields = (referential: UserFormReferential) => {
  const { setValue } = useFormContext<FormValues>()
  const type = useWatch<FormValues, "type">({ name: fields.type })

  const selectedType = referential.type.find(({ code }) => code === type)
  const isExternSpecificationVisible =
    selectedType?.refs?.refs === "externSpecification"
  setValue("isExternSpecificationVisible", isExternSpecificationVisible)

  return {
    selectedType,
    isExternSpecificationVisible,
    isFunctionDisabled: type === "HOLDER",
    isFunctionVisible: type !== "ADVISOR" && type !== "EXTERN",
  }
}
