import {
  api,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
  useToaster,
} from "@pharmupp/p3-front-commons"

export const useRatingApi = ({ labId }: { labId: string }) => {
  const endpoint = `/api/laboratories/adherent/${labId}/rating/summary`
  const queryKey = ["laboratories", labId, "adherent", "rating"]

  const toaster = useToaster()
  const queryClient = useQueryClient()

  // GET
  const { data: rating } = useSuspenseQuery({
    queryKey,
    queryFn: () => api.get<ApiLabRating>(endpoint),
  })

  // UPDATE
  const { mutate: updateRating } = useMutation({
    mutationFn: (userRating: number) =>
      api.put<ApiLabRating>(endpoint, {
        body: JSON.stringify({ value: userRating }),
      }),
    onSuccess(newRating) {
      toaster.success("Note envoyée avec succès")
      queryClient.invalidateQueries({ queryKey })
    },
    onError() {
      toaster.error("Erreur lors de l'envoi de la note")
    },
  })

  return { rating, updateRating }
}

export type ApiLabRating = {
  /** User rating */
  value?: number
  /** All users rating */
  averageValue?: number
  ratingsCount: number
  averageQualityValue?: number
  averageSalesConditionsValue?: number
  averageProductAvailabilityValue?: number
  averageSupportValue?: number

  /** Max rating (eg: 5) */
  max: number
}
